<template>
  <b-modal id="online-sale-transaction-modal" ok-only>
    <b-card
      title="Detalles de la transacción"
      class="mb-0"
      header-tag="header"
      header-class="p-2"
      v-if="selectedOrder"
    >
      <b-row>
        <b-col cols="12" md="6" class="mb-2">
          <b-card-text>
            <b>Orden:</b> #{{ selectedOrder.auto_inc_folio }}
          </b-card-text>
          <b-card-text>
            <b>Fecha:</b> {{ selectedOrder.created_at | dateNtime }}
          </b-card-text>
          <b-card-text>
            <b>Cliente:</b> {{ selectedOrder.store_clerk.name }}
          </b-card-text>
          <b-card-text>
            <b>Correo:</b> {{ selectedOrder.store_clerk.email }}
          </b-card-text>
          <b-card-text>
            <b>Total: </b> {{ selectedOrder.total | money }}
          </b-card-text>
          <b-card-text>
            <b>Estado de pago: </b>
            <b-badge
              pill
              :variant="
                selectedOrder.payment_status === 'payed'
                  ? 'light-success'
                  : selectedOrder.payment_status === 'pending'
                  ? 'light-warning'
                  : 'light-danger'
              "
            >
              {{ selectedOrder.payment_status | paymentStatus }}
            </b-badge>
          </b-card-text>
          <b-card-text>
            <b>Estado de entrega: </b>
            <b-badge
              pill
              :class="selectedOrder.delivery_status | deliveryStatusClass"
              class="whitespace-normal"
              :variant="
                selectedOrder.delivery_status === 'delivered'
                  ? 'light-success'
                  : selectedOrder.delivery_status === 'assigning'
                  ? 'light-warning'
                  : selectedOrder.delivery_status === 'assigned'
                  ? 'light-primary'
                  : selectedOrder.delivery_status ===
                      'rejected_by_dispatcher' ||
                    selectedOrder.delivery_status === 'cancelled_by_customer' ||
                    selectedOrder.delivery_status ===
                      'cancelled_by_establishment'
                  ? 'light-danger'
                  : 'light-info'
              "
            >
              <feather-icon
                :icon="selectedOrder.delivery_status | deliveryStatusIcon"
                :class="selectedOrder.delivery_status | deliveryStatusClass"
                class="mr-1"
              />
              {{ selectedOrder.delivery_status | deliveryStatus }}
            </b-badge>
          </b-card-text>
          <b-card-text v-if="selectedOrder.dispatcher_name">
            <b>Repartidor: </b>
            <b-badge
              v-if="
                selectedOrder.dispatcher_id &&
                selectedOrder.delivery_status !== 'rejected_by_dispatcher'
              "
              pill
              :class="selectedOrder.delivery_status | deliveryStatusClass"
              :variant="
                selectedOrder.delivery_status === 'delivered'
                  ? 'light-success'
                  : selectedOrder.delivery_status === 'assigning'
                  ? 'light-warning'
                  : selectedOrder.delivery_status === 'assigned'
                  ? 'light-primary'
                  : selectedOrder.delivery_status === 'rejected_by_dispatcher'
                  ? 'light-danger'
                  : 'light-info'
              "
            >
              {{ selectedOrder.dispatcher.name }}
            </b-badge>
          </b-card-text>
          <b-card-text>
            <b>Tipo de pago: </b>
            <b-badge
              v-if="selectedOrder.order_payments_attributes[0]"
              pill
              :variant="
                selectedOrder.order_payments_attributes[0].payment_type ===
                'cash'
                  ? 'light-success'
                  : selectedOrder.order_payments_attributes[0].payment_type ===
                    'walleat'
                  ? 'light-info'
                  : selectedOrder.order_payments_attributes[0].payment_type ===
                      'bankcard' ||
                    selectedOrder.order_payments_attributes[0].payment_type ===
                      'bank_deposit'
                  ? 'light-warning'
                  : 'light-primary'
              "
            >
              {{
                selectedOrder.order_payments_attributes[0].payment_type
                  | paymentType
              }}
            </b-badge>
            <b-badge
              v-else
              pill
              :variant="
                selectedOrder.payment_type === 'cash'
                  ? 'light-success'
                  : selectedOrder.payment_type === 'walleat'
                  ? 'light-info'
                  : selectedOrder.payment_type === 'bankcard' ||
                    selectedOrder.payment_type === 'bank_deposit'
                  ? 'light-warning'
                  : 'light-primary'
              "
            >
              {{ selectedOrder.payment_type | paymentTypeNum }}
            </b-badge>
          </b-card-text>
        </b-col>
      </b-row>
      <div>
        <b-button
          v-if="
            selectedOrder.delivery_status !== 'delivered' &&
            userData.role_name !== 'establishment_presale_clerk'
          "
          :variant="'success'"
          @click="markAsDelivered(selectedOrder)"
          size="sm"
        >
          ¿Entregado?
        </b-button>
      </div>

      <div class="mt-1">
        <b-button
          v-if="
            selectedOrder.delivery_status == 'assigned' &&
            userData.role_name !== 'establishment_presale_clerk'
          "
          :variant="'primary'"
          @click="selectDispatcher(selectedOrder)"
          size="sm"
        >
          Cambiar
        </b-button>
        <b-button
          v-else-if="
            selectedOrder.dispatcher_id &&
            selectedOrder.delivery_status === 'assigning' &&
            userData.role_name !== 'establishment_presale_clerk'
          "
          :variant="'success'"
          @click="openConfirmDispatcherModal(selectedOrder)"
          size="sm"
        >
          Confirmar
        </b-button>
        <b-button
          v-else-if="
            (selectedOrder.delivery_status == 'rejected_by_dispatcher' ||
              (!selectedOrder.dispatcher_id &&
                selectedOrder.delivery_status !== 'delivered')) &&
            userData.role_name !== 'establishment_presale_clerk'
          "
          :variant="'warning'"
          @click="selectDispatcher(selectedOrder)"
          size="sm"
        >
          Asignar
        </b-button>
      </div>
      <div class="mt-1">
        <b-button
          :id="`invoice-row-${selectedOrder.id}-preview`"
          :to="{ name: 'order-view', params: { id: selectedOrder.id } }"
          :variant="'primary'"
          size="sm"
        >
          Ver detalles
        </b-button>
      </div>
    </b-card>
  </b-modal>
</template>

<script>
export default {
  props: {
    selectedOrder: {
      type: Object,
      default: () => {},
    },
    userData: {
      type: Object,
      default: () => {},
    },
    markAsDelivered: {
      type: Function,
      required: true,
    },
    openConfirmDispatcherModal: {
      type: Function,
      required: true,
    },
    selectDispatcher: {
      type: Function,
      required: true,
    },
  },
};
</script>

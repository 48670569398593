var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"mx-1 mb-1"},[_c('b-col',{attrs:{"cols":"12","sm":"6","md":"3","lg":"3","xl":"3"}},[_c('span',{staticClass:"mr-1"},[_vm._v("Tipo de pago")]),_c('v-select',{staticClass:"invoice-filter-select",attrs:{"value":_vm.paymentTypeFilter,"dir":'ltr',"options":_vm.paymentTypeOptions,"reduce":function (option) { return option.value; },"clearable":false,"placeholder":"Tipo de pago"},on:{"input":_vm.onPaymentTypeChange},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var text = ref.text;
return [_c('span',{staticClass:"text-truncate overflow-hidden"},[_vm._v(_vm._s(text))])]}},{key:"option",fn:function(ref){
var text = ref.text;
return [_c('span',[_vm._v(_vm._s(text))])]}}])})],1),_c('b-col',{attrs:{"cols":"12","sm":"6","md":"3","lg":"3","xl":"3"}},[_c('span',{staticClass:"mr-1"},[_vm._v("Entrega")]),_c('v-select',{staticClass:"invoice-filter-select",attrs:{"value":_vm.deliveryStatusFilter,"dir":'ltr',"options":_vm.deliveryStatusOptions,"reduce":function (option) { return option.value; },"clearable":false,"placeholder":"Estado de entrega"},on:{"input":_vm.onDeliveryStatusChange},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var text = ref.text;
return [_c('span',{staticClass:"text-truncate overflow-hidden"},[_vm._v(_vm._s(text))])]}},{key:"option",fn:function(ref){
var text = ref.text;
return [_c('span',[_vm._v(_vm._s(text))])]}}])},[(_vm.loading)?_c('b-spinner',{attrs:{"small":"","label":"Loading"}}):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6","md":"3","lg":"3","xl":"3"}},[_c('span',{staticClass:"mr-1"},[_vm._v("Estado")]),_c('v-select',{staticClass:"invoice-filter-select",attrs:{"value":_vm.cancelationStatusFilter,"dir":'ltr',"options":_vm.cancelationStatusOptions,"reduce":function (option) { return option.value; },"clearable":false,"placeholder":"¿Está cancelada?"},on:{"input":_vm.onCancelationStatusChange},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var text = ref.text;
return [_c('span',{staticClass:"text-truncate overflow-hidden"},[_vm._v(_vm._s(text))])]}},{key:"option",fn:function(ref){
var text = ref.text;
return [_c('span',[_vm._v(_vm._s(text))])]}}])})],1),_c('b-col',{attrs:{"cols":"12","sm":"6","md":"3","lg":"3","xl":"3"}},[_c('span',{staticClass:"mr-1"},[_vm._v("Folio")]),_c('b-form-input',{attrs:{"id":"v-folio","variant":"outline-primary","placeholder":"1234","type":"text"},on:{"input":function($event){return _vm.$emit('update:folioFilter', $event)}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
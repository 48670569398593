<template>
  <b-modal
    id="select-dispatcher"
    title="Selecciona un repartidor"
    no-stacking
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    hide-footer
  >
    <b-card>
      <h3 class="d-flex justify-content-center align-items-center mb-2">
        {{ "Añade repartidor a la orden con dirección de entrega en:" }}
      </h3>
      <h5 class="d-flex justify-content-center align-items-center mb-2">
        {{ delivery_address }}
      </h5>
      <validation-observer
        #default="{ handleSubmit, invalid }"
        ref="dispatcherFormObserver"
      >
        <!-- Form -->
        <b-form
          @submit.prevent="handleSubmit(handleFormValidation)"
          @reset.prevent="resetForm"
        >
          <b-form-group
            label="Seleccione al repartidor que deseas asignar a la entrega"
            class="mb-1"
            label-for="dispatchers"
          >
            <validation-provider
              #default="{ errors }"
              name="dispatchers"
              rules="required"
            >
              <v-select
                id="dispatchers"
                v-model="order.dispatcher_id"
                label="dispatchers"
                :options="dispatchers"
                :reduce="(option) => option.id"
              >
                <template #selected-option="{ name }">
                  <span> {{ name }}</span>
                </template>
                <template #option="{ name }">
                  <span> {{ name }}</span>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-col cols="6">
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-danger"
                @click="closeSelectDispatcherModal()"
              >
                Cancelar
              </b-button>
            </b-col>
            <b-col cols="6">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                class="mr-2"
                type="submit"
                :disabled="invalid"
              >
                Guardar
              </b-button>
            </b-col>
          </div>
        </b-form>
      </validation-observer>
    </b-card>
  </b-modal>
</template>

<script>
import ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  props: {
    delivery_address: {
      type: String,
      default: "",
    },
    onSubmit: {
      type: Function,
      required: true,
    },
    order: {
      type: Object,
      required: true,
    },
    dispatchers: {
      type: Array,
      default: () => [],
    },
    closeSelectDispatcherModal: {
      type: Function,
      required: true,
    },
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    vSelect,
  },
  directives: {
    ripple,
  },
  methods: {
    handleFormValidation() {
      this.$refs.dispatcherFormObserver.validate().then((success) => {
        if (success) {
          this.onSubmit();
        }
      });
    },
  },
};
</script>

<template>
  <b-list-group class="sales-list px-1 mt-2">
    <b-list-group-item
      v-for="order in orders"
      :key="order.id"
      class="d-flex align-items-center flex-wrap flex-gap"
      @click="handleSelectedTransaction(order)"
    >
      <b-link
        :to="{ name: 'order-view', params: { id: order.id } }"
        class="font-weight-bold"
      >
        #{{ order.auto_inc_folio }}
      </b-link>
      <b-media
        :id="`invoice-row-${order.id}-preview-address`"
        vertical-align="center"
      >
        <span class="font-weight-bold d-block text-break">
          {{ order.store_clerk.name }}
        </span>
        <small class="text-muted text-break">{{
          order.store_clerk.email
        }}</small>
      </b-media>
      <div>
        <feather-icon
          :icon="order.order_type | orderTypeIcon"
          :class="order.order_type | orderTypeClass"
          class="mr-1"
        />
        ${{ order.total | money }}
      </div>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
export default {
  props: {
    orders: {
      type: Array,
      default: () => [],
    },
    handleSelectedTransaction: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-gap {
  gap: 1rem;
}
</style>

<template>
  <b-modal
    id="confirm-dispatcher"
    title="Confirma al despachador"
    no-stacking
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
  >
    <h3 class="d-flex justify-content-center align-items-center mb-2">
      {{ "¿Estás seguro que deseas confirmar a" }} {{ dispatcher_name }}
      {{ "para entregar en la siguiente dirección:" }}
    </h3>
    <h5 class="d-flex justify-content-center align-items-center mb-2">
      {{ delivery_address }}
    </h5>
    <template #modal-footer>
      <b-button variant="warning" @click="changeDispatcher()">
        Cambiar
      </b-button>
      <b-button :variant="'success'" @click="assignDispatcher()">
        Confirmar
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  components: {},
  props: {
    changeDispatcher: {
      type: Function,
      required: true,
    },
    assignDispatcher: {
      type: Function,
      required: true,
    },
    dispatcher_name: {
      type: String,
      default: "",
    },
    delivery_address: {
      type: String,
      default: "",
    },
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"select-dispatcher","title":"Selecciona un repartidor","no-stacking":"","no-close-on-esc":"","no-close-on-backdrop":"","hide-header-close":"","hide-footer":""}},[_c('b-card',[_c('h3',{staticClass:"d-flex justify-content-center align-items-center mb-2"},[_vm._v(" "+_vm._s("Añade repartidor a la orden con dirección de entrega en:")+" ")]),_c('h5',{staticClass:"d-flex justify-content-center align-items-center mb-2"},[_vm._v(" "+_vm._s(_vm.delivery_address)+" ")]),_c('validation-observer',{ref:"dispatcherFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.handleFormValidation)},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('b-form-group',{staticClass:"mb-1",attrs:{"label":"Seleccione al repartidor que deseas asignar a la entrega","label-for":"dispatchers"}},[_c('validation-provider',{attrs:{"name":"dispatchers","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"dispatchers","label":"dispatchers","options":_vm.dispatchers,"reduce":function (option) { return option.id; }},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var name = ref.name;
return [_c('span',[_vm._v(" "+_vm._s(name))])]}},{key:"option",fn:function(ref){
var name = ref.name;
return [_c('span',[_vm._v(" "+_vm._s(name))])]}}],null,true),model:{value:(_vm.order.dispatcher_id),callback:function ($$v) {_vm.$set(_vm.order, "dispatcher_id", $$v)},expression:"order.dispatcher_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"d-flex mt-2"},[_c('b-col',{attrs:{"cols":"6"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"outline-danger"},on:{"click":function($event){return _vm.closeSelectDispatcherModal()}}},[_vm._v(" Cancelar ")])],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"success","type":"submit","disabled":invalid}},[_vm._v(" Guardar ")])],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <b-modal
    id="mark-as-delivered"
    title="Confirmar entrega a cliente"
    no-stacking
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    ok-title="Finalizar"
    ok-variant="success"
    cancel-title="Cancelar"
    cancel-variant="outline-danger"
    @ok="confirmsDelivery()"
  >
    <b-card>
      <div class="d-flex align-items-center">
        <feather-icon
          size="50"
          class="mr-1"
          icon="AlertTriangleIcon"
          style="color:orange;'"
        />
        <b-card-title
          v-if="order.payment_status === 'pending'"
          class="ml-25"
          style="color:orange;'"
        >
          Antes de entregar cobra al cliente ${{ orderTotal | money }}
        </b-card-title>
        <b-card-title v-if="order.payment_status === 'payed'" class="ml-25">
          ¿Estás seguro que quieres marcar como entregado?
        </b-card-title>
      </div>
    </b-card>
  </b-modal>
</template>

<script>
export default {
  components: {},
  props: {
    order: {
      type: Object,
      required: true,
    },
    orderTotal: {
      type: String,
      default: "",
    },
    confirmsDelivery: {
      type: Function,
      required: true,
    },
  },
};
</script>

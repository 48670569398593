<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <header class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          sm="auto"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <h3>Ventas en linea</h3>
        </b-col>

        <!-- Search -->
        <b-col>
          <div
            class="
              d-flex
              flex-column flex-sm-row
              align-items-start
              justify-content-end
            "
          >
            <slot />
          </div>
        </b-col>
      </b-row>

      <b-button variant="success" @click="downloadFile()">
        Obtener archivo de conciliación
      </b-button>
    </header>
    <mark-as-delivered-modal
      :order="order"
      :orderTotal="orderTotal"
      :confirmsDelivery="confirmsDelivery"
    />
    <!-- Assign dispatcher modal-->
    <assign-dispatcher-modal
      :delivery_address="delivery_address"
      :onSubmit="onSubmit"
      :order="order"
      :dispatchers="dispatchers"
      :closeSelectDispatcherModal="closeSelectDispatcherModal"
    />

    <!-- Confirm dispatcher modal-->
    <confirm-dispatcher-modal
      :changeDispatcher="changeDispatcher"
      :assignDispatcher="assignDispatcher"
      :dispatcher_name="dispatcher_name"
      :delivery_address="delivery_address"
    />

    <online-sales-transactions-filters
      :paymentTypeFilter.sync="paymentTypeFilter"
      :deliveryStatusFilter.sync="deliveryStatusFilter"
      :folioFilter.sync="folioFilter"
      :cancelationStatusFilter.sync="cancelationStatusFilter"
      :loading="loading"
    />

    <online-sales-transactions-table
      :orders="ordersShown"
      :userData="userData"
      :selectDispatcher="selectDispatcher"
      :openConfirmDispatcherModal="openConfirmDispatcherModal"
      :markAsDelivered="markAsDelivered"
    />

    <online-sales-transactions-list
      :orders="ordersShown"
      :handleSelectedTransaction="handleSelectedTransaction"
    />

    <online-sale-transaction-modal
      :selectedOrder="selectedOrder"
      :userData="userData"
      :openConfirmDispatcherModal="openConfirmDispatcherModal"
      :markAsDelivered="markAsDelivered"
      :selectDispatcher="selectDispatcher"
    />

    <div class="mx-2 mb-2 mt-1">
      <pagination
        :pagination="pagination"
        :loading="loading"
        :entriesPerPage.sync="entriesPerPage"
        :handlePagination="handlePagination"
      />
    </div>
  </b-card>
</template>

<script>
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { ValidationObserver } from "vee-validate";
import { mapActions, mapGetters, mapMutations } from "vuex";

import ConfirmDispatcherModal from "./ConfirmDispatcherModal.vue";
import AssignDispatcherModal from "./AssignDispatcherModal.vue";
import MarkAsDeliveredModal from "./MarkAsDeliveredModal.vue";
import OnlineSalesTransactionsTable from "./OnlineSalesTransactionsTable.vue";
import OnlineSalesTransactionsList from "./OnlineSalesTransactionsList.vue";
import OnlineSalesTransactionsFilters from "./OnlineSalesTransactionsFilters.vue";
import OnlineSaleTransactionModal from "./OnlineSaleTransactionModal.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Pagination from "@/@core/components/Pagination.vue";

import { required } from "@validations";

export default {
  components: {
    vSelect,

    OnlineSalesTransactionsTable,
    OnlineSalesTransactionsList,
    OnlineSalesTransactionsFilters,
    OnlineSaleTransactionModal,
    ConfirmDispatcherModal,
    AssignDispatcherModal,
    MarkAsDeliveredModal,
    Pagination,

    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    dates: {
      type: [Array, String],
      default: () => null,
    },
  },
  data() {
    return {
      required,
      ordersShown: null,
      loading: false,
      userData: JSON.parse(localStorage.getItem("userData")),
      entriesPerPage: "10",
      searchQuery: "",
      paymentTypeFilter: "",
      deliveryStatusFilter: "",
      folioFilter: "",
      cancelationStatusFilter: "",
      dispatchers: null,
      orderTotal: null,
      delivery_address: null,
      dispatcher_name: null,
      order: {
        id: null,
        dispatcher_id: null,
      },
      selectedOrder: null,
    };
  },

  beforeMount() {
    this.ordersShown = null;
    this.fetchOrders({
      by_order_type: "sell",
      by_order_mode: ["online", "presale"],
      by_store: this.$route.params.id,
    })
      // eslint-disable-next-line no-unused-vars
      .then((response) => {
        this.ordersShown = this.orders;
      })
      .catch((error) => {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Error",
              text: error.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          },
          {
            position: "top-right",
          }
        );
      });
    this.fetchUsers({
      by_resource_id: this.$route.params.id,
      by_active_status: true,
    });
    this.fetchStore(this.$route.params.id);
  },

  computed: {
    ...mapGetters("orders", ["pagination", "orders"]),
    ...mapGetters("users", ["users"]),
    ...mapGetters("stores", ["currentStore"]),
  },
  watch: {
    entriesPerPage() {
      this.loading = true;
      this.fetchOrders({
        by_order_type: "sell",
        by_order_mode: ["online", "presale"],
        by_store: this.$route.params.id,
        by_auto_folio: this.folioFilter,
        by_delivery_status: this.deliveryStatusFilter,
        by_payment_type: this.paymentTypeFilter,
        by_is_refund: this.cancelationStatusFilter,
        meta: {
          pagination: {
            page: this.pagination.page,
            per_page: this.entriesPerPage,
          },
        },
      }).then(() => {
        this.loading = false;
        this.ordersShown = this.orders;
      });
    },
    folioFilter() {
      let dates;
      if (this.dates) {
        dates = {
          start_date: Array.isArray(this.dates)
            ? this.dates[0]
            : this.dates.substring(0, 10),
          end_date: Array.isArray(this.dates)
            ? this.dates[1]
            : this.dates.substring(14),
        };
      } else {
        dates = null;
      }
      this.loading = true;

      this.fetchOrders({
        by_order_type: "sell",
        by_order_mode: ["online", "presale"],
        by_store: this.$route.params.id,
        by_auto_folio: this.folioFilter,
        by_date: dates,
        by_delivery_status: this.deliveryStatusFilter,
        by_payment_type: this.paymentTypeFilter,
        by_is_refund: this.cancelationStatusFilter,
      }).then(() => {
        this.loading = false;
        this.ordersShown = this.orders;
      });
    },
    paymentTypeFilter() {
      let dates;
      if (this.dates) {
        dates = {
          start_date: Array.isArray(this.dates)
            ? this.dates[0]
            : this.dates.substring(0, 10),
          end_date: Array.isArray(this.dates)
            ? this.dates[1]
            : this.dates.substring(14),
        };
      } else {
        dates = null;
      }
      this.loading = true;
      this.fetchOrders({
        by_order_type: "sell",
        by_order_mode: ["online", "presale"],
        by_store: this.$route.params.id,
        by_auto_folio: this.folioFilter,
        by_date: dates,
        by_delivery_status: this.deliveryStatusFilter,
        by_payment_type: this.paymentTypeFilter,
        by_is_refund: this.cancelationStatusFilter,
      }).then(() => {
        this.loading = false;
        this.ordersShown = this.orders;
      });
    },
    deliveryStatusFilter() {
      let dates;
      if (this.dates) {
        dates = {
          start_date: Array.isArray(this.dates)
            ? this.dates[0]
            : this.dates.substring(0, 10),
          end_date: Array.isArray(this.dates)
            ? this.dates[1]
            : this.dates.substring(14),
        };
      } else {
        dates = null;
      }
      this.loading = true;
      this.fetchOrders({
        by_order_type: "sell",
        by_order_mode: ["online", "presale"],
        by_store: this.$route.params.id,
        by_auto_folio: this.folioFilter,
        by_date: dates,
        by_delivery_status: this.deliveryStatusFilter,
        by_payment_type: this.paymentTypeFilter,
        by_is_refund: this.cancelationStatusFilter,
      }).then(() => {
        this.loading = false;
        this.ordersShown = this.orders;
      });
    },
    cancelationStatusFilter() {
      let dates;
      if (this.dates) {
        dates = {
          start_date: Array.isArray(this.dates)
            ? this.dates[0]
            : this.dates.substring(0, 10),
          end_date: Array.isArray(this.dates)
            ? this.dates[1]
            : this.dates.substring(14),
        };
      } else {
        dates = null;
      }
      this.loading = true;
      this.fetchOrders({
        by_order_type: "sell",
        by_order_mode: ["online", "presale"],
        by_store: this.$route.params.id,
        by_auto_folio: this.folioFilter,
        by_date: dates,
        by_delivery_status: this.deliveryStatusFilter,
        by_payment_type: this.paymentTypeFilter,
        by_is_refund: this.cancelationStatusFilter,
      }).then(() => {
        this.loading = false;
        this.ordersShown = this.orders;
      });
    },
  },

  mounted() {
    this.ordersShown = this.orders;
    this.onlineOrdersConnection = new WebSocket(
      `${process.env.VUE_APP_WSS_URL}/cable?token=${this.userData.token}`
    );
    sessionStorage.setItem(
      "wsConnectionOnlineOrders",
      JSON.stringify(this.onlineOrdersConnection)
    );

    this.onlineOrdersConnection.onmessage = (event) => {
      const messagex = JSON.parse(event.data);
      if (typeof messagex.message === "object") {
        this.$swal({
          title: messagex.message.title,
          text: messagex.message.content_message,
          icon: "warning",
          showCancelButton: false,
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        this.fetchOrders({
          by_order_type: "sell",
          by_order_mode: ["online", "presale"],
          by_store: this.$route.params.id,
        })
          // eslint-disable-next-line no-unused-vars
          .then((response) => {
            this.ordersShown = this.orders;
          });
      }
    };

    this.onlineOrdersConnection.onopen = () => {
      // eslint-disable-next-line
      console.log("Successfully connected to the echo websocket server...");

      // eslint-disable-next-line
      this.onlineOrdersConnection.send(
        JSON.stringify({
          command: "subscribe",
          identifier: '{"channel":"ApplicationCable::MyChannel"}',
        })
      );
    };
  },
  destroyed() {
    this.onlineOrdersConnection.close();
    this.deleteOrders();
  },
  methods: {
    ...mapActions("orders", [
      "fetchOrders",
      "deliveryConfirmation",
      "confirmDispatcher",
      "generateOrdersConciliationFile",
    ]),
    ...mapActions("users", ["fetchUsers"]),
    ...mapActions("stores", ["fetchStore"]),
    ...mapMutations("orders", ["deleteOrders"]),
    downloadFile() {
      this.generateOrdersConciliationFile({
        by_store: this.$route.params.id,
        by_delivery_status: this.deliveryStatusFilter,
        by_payment_type: this.paymentTypeFilter,
        by_is_refund: this.cancelationStatusFilter,
      }).then((response) => {
        const csvContent = `data:text/csv;charset=utf-8,${response.split(",")}`;
        const encodedUri = encodeURI(csvContent);
        window.open(encodedUri);
      });
    },
    handleSelectedTransaction(order) {
      this.selectedOrder = order;
      this.$bvModal.show("online-sale-transaction-modal");
    },
    handlePagination({ page, per_page }) {
      let dates;
      if (this.dates) {
        dates = {
          start_date: Array.isArray(this.dates)
            ? this.dates[0]
            : this.dates.substring(0, 10),
          end_date: Array.isArray(this.dates)
            ? this.dates[1]
            : this.dates.substring(14),
        };
      } else {
        dates = null;
      }
      this.loading = true;
      this.fetchOrders({
        by_order_type: "sell",
        by_order_mode: ["online", "presale"],
        by_store: this.$route.params.id,
        by_auto_folio: this.folioFilter,
        by_date: dates,
        by_delivery_status: this.deliveryStatusFilter,
        by_payment_type: this.paymentTypeFilter,
        by_is_refund: this.cancelationStatusFilter,
        meta: {
          pagination: {
            page,
            per_page,
          },
        },
      }).then(() => {
        this.loading = false;
        this.ordersShown = this.orders;
      });
    },
    formatDate(date) {
      const d = new Date(date);
      let month = `${d.getMonth() + 1}`;
      let day = `${d.getDate()}`;
      const year = d.getFullYear();

      if (month.length < 2) month = `0${month}`;
      if (day.length < 2) day = `0${day}`;

      return [year, month, day].join("-");
    },
    formatFirstDate(date) {
      const d = new Date(date);
      let month = `${d.getMonth() + 1}`;
      let day = "1";
      const year = d.getFullYear();

      if (month.length < 2) month = `0${month}`;
      if (day.length < 2) day = `0${day}`;

      return [year, month, day].join("-");
    },
    changeDispatcher() {
      this.defineDispatchers();
      this.$bvModal.hide("online-sale-transaction-modal");
      this.$bvModal.show("select-dispatcher");
    },
    selectDispatcher(order) {
      this.defineDispatchers();
      this.order.id = order.id;
      this.delivery_address = `${order.delivery_location.street} ${order.delivery_location.ext_number} (num. int ${order.delivery_location.int_number}),
        CP: ${order.delivery_location.postal_code}, ${order.delivery_location.city}, ${order.delivery_location.state}, ${order.delivery_location.country} `;
      this.$bvModal.hide("online-sale-transaction-modal");
      this.$bvModal.show("select-dispatcher");
    },
    markAsDelivered(order) {
      this.order.id = order.id;
      this.orderTotal = order.total;
      this.order.payment_status = order.payment_status;
      this.$bvModal.hide("online-sale-transaction-modal");
      this.$bvModal.show("mark-as-delivered");
    },
    defineDispatchers() {
      this.dispatchers = this.users; // .filter(item => item.role_name.toLowerCase().includes('dispatcher'))
      console.log(this.dispatchers);
    },
    closeSelectDispatcherModal() {
      this.$bvModal.hide("select-dispatcher");
    },
    openConfirmDispatcherModal(order) {
      this.order.id = order.id;
      this.order.dispatcher_id = order.dispatcher_id;
      this.delivery_address = `${order.delivery_location.street} ${order.delivery_location.ext_number} (num. int ${order.delivery_location.int_number}),
        CP: ${order.delivery_location.postal_code}, ${order.delivery_location.city}, ${order.delivery_location.state}, ${order.delivery_location.country} `;
      this.dispatcher_name = order.dispatcher.name;
      this.$bvModal.hide("online-sale-transaction-modal");
      this.$bvModal.show("confirm-dispatcher");
    },
    onSubmit() {
      this.assignDispatcher();
      this.$bvModal.hide("select-dispatcher");
    },
    confirmsDelivery() {
      this.deliveryConfirmation({
        id: this.order.id,
      })
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          this.$swal({
            title: "Orden marcada como entrega!",
            icon: "success",
            customClass: {
              confirmButton: "btnx btn-primary",
            },
            buttonsStyling: false,
          });
          this.fetchOrders({
            by_order_type: "sell",
            by_order_mode: "online",
            by_store: this.$route.params.id,
          })
            // eslint-disable-next-line no-unused-vars
            .then((responseNew) => {
              this.ordersShown = this.orders;
            });
          this.paymentTypeFilter = "";
          this.deliveryStatusFilter = "";
          this.folioFilter = "";
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Error",
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.messages.base[0],
            },
          });
        });
    },
    assignDispatcher() {
      this.$bvModal.hide("confirm-dispatcher");
      this.confirmDispatcher(this.order)
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          this.$swal({
            title: "Orden asignada a repartidor!",
            text: "Has asignado correctamente un repartidor a la orden; espera que el repartidor confirme la orden",
            icon: "success",
            customClass: {
              confirmButton: "btnx btn-primary",
            },
            buttonsStyling: false,
          });
          this.order.id = null;
          this.order.dispatcher_id = null;
          this.delivery_address = null;
          this.dispatcher_name = null;
          this.fetchOrders({
            by_order_type: "sell",
            by_order_mode: "online",
            by_store: this.$route.params.id,
          })
            // eslint-disable-next-line no-unused-vars
            .then((responseNew) => {
              this.ordersShown = this.orders;
            });
          this.paymentTypeFilter = "";
          this.deliveryStatusFilter = "";
          this.folioFilter = "";
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Error",
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.messages.base[0],
            },
          });
          this.order.id = null;
          this.order.dispatcher_id = null;
          this.delivery_address = null;
          this.dispatcher_name = null;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";

.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.search-input {
  max-width: 200px;
}

.sales-table {
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
}

.sales-list {
  display: block;

  @media (min-width: 768px) {
    display: none;
  }
}
</style>
